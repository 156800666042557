import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FeatureSection from './FeatureSection';
import PartnerSection from './PartnerSection';
import FAQSection from './FAQSection';
import ContactSection from './ContactSection';

function Layout() {
  return (
    <div className="flex overflow-hidden flex-col bg-neutral-900">
      <Header />
      <main>
        <FeatureSection />
        <PartnerSection />
        <FAQSection />
        <ContactSection />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;