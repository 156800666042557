import React from "react";
import FAQItem from "./FAQItem";

const faqs = [
  {
    question: "Apa itu inkluseeds.id?",
    answer:
      "Platform pencari kerja yang dapat diakses oleh penyandang disabilitas, serta fitur yang memungkinkan interaksi langsung antara perusahaan, Disnaker, dan penyandang disabilitas.",
  },
  {
    question: "Apa tujuan inkluseeds.id?",
    answer:
      "Menjadi salah satu solusi kunci untuk menciptakan ketenagakerjaan yang adil dan inklusif, serta memberikan manfaat jangka panjang bagi semua pihak yang terlibat.",
  },
  {
    question: "Apa keuntungan gabung inkluseeds.id?",
    answer:
      "Sistem ini khusus atau tertutup hanya untuk pencari kerja penyandang disabilitas. Pencari kerja nondisabilitas tidak akan bisa mendaftar ke dalam sistem ini. Selain itu, sistem ini tidak hanya memuat informasi tentang pencari dan penyedia kerja, tetapi juga ada konten-konten berupa visual, audio, dan video yang diharapkan bisa mendukung pengguna untuk menumbuhkan dan meningkatkan keterampilan.",
  },
  {
    question: "Siapa pembuat inkluseeds.id?",
    answer:
      "inkluseeds.id dibuat oleh Telkom University Surabaya, dan didukung oleh Komisi Nasional Disabilitas dan Disnaertrans Provinsi Jawa Timur",
  },
  {
    question: "Siapa yang bisa bergabung inkluseeds.id?",
    answer:
      "Penyandang disabilitas jenis apapun. Usia 18-40 tahun. Pendidikan minimal SMA/SMK/Sederajat. Mampu mengoperasikan internet.",
  },
  {
    question: "Informasi apa yang didapat dengan bergabung inkluseeds.id?",
    answer:
      "Anda akan mendapatkan informasi lowongan pekerjaan yang telah dikhususkan untuk Penyandang Disabilitas. Kemudian, Anda dapat melakukan eksplorasi ke konten-konten sarat informasi untuk meningkatkan kapasitas pengembangan pribadi Anda.",
  },
];

function FAQSection() {
  return (
    <section className="flex overflow-hidden flex-col items-center px-20 pt-28 pb-16 mt-16 w-full bg-green-400 max-md:px-5 max-md:pt-24 max-md:mt-10 max-md:max-w-full">
      <div className="w-full max-w-[1225px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col text-black max-md:mt-10">
              <h2 className="self-start text-5xl font-bold leading-[74px] max-md:text-4xl max-md:leading-[69px]">
                Frequently Asked <br /> Questions
              </h2>
              <p className="mt-8 text-2xl leading-10">
                Pertanyaan seputar Platform Jaringan Kerja antara Penyandang
                Disabilitas, Disnaker, dan Perusahaan
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col mt-3.5 w-full text-2xl text-white max-md:mt-10 max-md:max-w-full">
              {faqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
