import React from 'react';

const partners = [
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/c4d5e81ae7d3173a1a0f9b56327fd1f33c09619662c6233afb2bb26b9c66ae8c?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Partner logo 1" },
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/744be7b836e3768b6576ca7d93de56082ed6f98a98e7b67f7a8b34bcc74049b5?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Partner logo 2" },
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/f69003b64082c603f29a7349ee93569c4e5ea53d87767eac2e1a354b31b3d963?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Partner logo 3" }
];

function PartnerSection() {
  return (
    <section className="flex overflow-hidden flex-col items-center py-11 mt-16 w-full min-h-[585px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full text-center text-white w-[903px]">
        <h2 className="text-5xl font-bold max-md:max-w-full max-md:text-4xl">
          Didukung Oleh
        </h2>
        <p className="self-center mt-4 text-3xl tracking-wider leading-10 max-md:max-w-full">
          Berkontribusi dalam mengembangkan layanan <br /> dan menciptakan nilai tambah bagi penyandang disabilitas
        </p>
      </div>
      <div className="flex flex-col items-center pl-2 mt-12 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-8 justify-center items-center max-w-full w-[1440px]">
          {partners.map((partner, index) => (
            <div key={index} className="flex overflow-hidden flex-col justify-center items-center self-stretch py-6 my-auto bg-white rounded-xl min-h-[294px] min-w-[240px] w-[361px]">
              <img loading="lazy" src={partner.image} alt={partner.alt} className="object-contain max-w-full aspect-square w-[247px]" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PartnerSection;