import React from 'react';

const navItems = ["Beranda", "Tentang", "Fitur", "Mitra", "FAQs"];

function Footer() {
  return (
    <footer className="flex flex-col items-center py-48 pr-14 pl-32 mt-7 w-full bg-green-400 min-h-[345px] max-md:px-5 max-md:py-24 max-md:max-w-full">
      <div className="flex max-w-full bg-black min-h-[3px] w-[1200px]" />
      <div className="flex flex-wrap gap-10 justify-between items-center mt-5 max-w-full text-2xl leading-tight text-black w-[1200px]">
        <div className="self-stretch my-auto w-[338px]">
          Copyright © {new Date().getFullYear()} inkluseeds.id
        </div>
        <button 
          className="flex gap-3 self-stretch my-auto w-[200px]"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <span className="grow my-auto">Kembali ke atas</span>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d6b0041e8a61fa06ed50cca424903d7f94c5eb5fd4f00b4bd76f5c166f93be49?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da" alt="" className="object-contain shrink-0 aspect-square w-[47px]" />
        </button>
      </div>
      <div className="flex flex-wrap gap-10 justify-center items-center self-stretch mt-5 max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[504px] max-md:max-w-full">
          <div className="flex overflow-hidden flex-col justify-center px-1 py-6 max-w-full rounded-[100px] w-[156px]">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/979185a82d3d2441114f08aba62eee0a2a0af16fc610a7f0d5891719f8b99b22?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da" alt="Incluseeds logo" className="object-contain w-full aspect-[5.43]" />
          </div>
          <p className="mt-5 text-2xl leading-7 text-black max-md:max-w-full">
            Platform Jaringan Kerja antara Penyandang Disabilitas dan Perusahaan
          </p>
        </div>
        <nav className="flex flex-col items-start self-stretch my-auto text-2xl leading-tight text-black whitespace-nowrap min-w-[240px] w-[531px] max-md:max-w-full">
          <h3 className="font-bold max-md:max-w-full">Navigasi</h3>
          <ul className="flex flex-wrap gap-5 items-center mt-7 w-full max-md:max-w-full">
            {navItems.map((item, index) => (
              <li key={index} className="self-stretch my-auto">
                <a href={`#${item.toLowerCase()}`} className="hover:underline">{item}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;