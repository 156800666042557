import React from 'react';

function FeatureCard({ title, image, items }) {
  return (
    <div className="flex flex-col grow shrink px-2.5 min-w-[240px] w-[273px]">
      <div className="flex gap-2.5 items-center px-12 py-11 w-80 rounded-xl aspect-square bg-neutral-900 min-h-[315px] max-md:px-5">
        <img loading="lazy" src={image} alt={`${title} icon`} className="object-contain self-stretch my-auto aspect-[0.99] w-[225px]" />
      </div>
      <div className="flex flex-col items-center mt-8 w-full max-w-xs text-3xl text-black">
        <h3 className="text-4xl font-bold">{title}</h3>
        {items.map((item, index) => (
          <div key={index} className="flex gap-4 mt-5 w-full tracking-wide leading-8">
            <img loading="lazy" src={item.icon} alt="" className="object-contain shrink-0 self-start aspect-square w-[30px]" />
            <div className="flex-auto w-[263px]">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeatureCard;