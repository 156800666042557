import React from 'react';

const socialLinks = [
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/65ad01278147643556736da1bcbcbe98b08d37a42f9a4514cfe977fab5d5ec6d?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Social media icon 1" },
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/687b1a5516a9b06d546d82a7e1f2f31bfee3978c87a3b922103dffb4b4b81d55?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Social media icon 2" },
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/cc2f450b30a29d0cc50594de5fe29757e00a7936bb20e6c72a3ee6f1c9063d5b?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Social media icon 3" },
  { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/9576d9cec9c05d7cc670568be9f4d1c1b4d0a53c347145625da2fd2e9a218a5e?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", alt: "Social media icon 4" }
];

function ContactSection() {
  return (
    <section className="flex overflow-hidden flex-col items-center py-14 w-full max-md:max-w-full">
      <div className="flex flex-col max-w-full text-center text-white w-[1430px]">
        <div className="flex flex-col items-center w-full">
          <h2 className="text-5xl font-bold max-md:max-w-full max-md:text-4xl">
            Terhubung dengan kami
          </h2>
          <p className="mt-6 text-3xl tracking-wider leading-none max-md:max-w-full">
            Hubungi kami untuk mendapatkan informasi lebih lanjut
          </p>
        </div>
      </div>
      <div className="flex gap-10 justify-center items-center py-2.5 mt-16 w-full max-w-[1440px] max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-10 justify-center items-center self-stretch px-9 pt-2.5 my-auto min-w-[240px] w-[1440px] max-md:px-5">
          {socialLinks.map((link, index) => (
            <div key={index} className="flex flex-col self-stretch my-auto w-[196px]">
              <div className={`flex flex-col justify-center items-center px-8 rounded-full border border-white border-solid ${index === 0 ? 'bg-white' : 'bg-green-400'} bg-opacity-0 h-[196px] w-[196px] max-md:px-5`}>
                <img loading="lazy" src={link.image} alt={link.alt} className="object-contain aspect-square w-[135px]" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ContactSection;