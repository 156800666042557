import React, { useState } from 'react';

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`flex flex-col px-6 py-6 mt-5 w-full rounded-2xl border border-black border-solid bg-stone-900 ${answer && isOpen ? 'min-h-[196px]' : 'min-h-[96px]'} max-md:px-5 max-md:max-w-full`}>
      <div className="flex flex-wrap gap-6 items-center w-full font-bold leading-snug max-md:max-w-full">
        <div className="flex-1 shrink self-stretch my-auto bg-blend-normal basis-0 max-md:max-w-full">
          {question}
        </div>
        <button onClick={() => setIsOpen(!isOpen)} aria-expanded={isOpen} aria-controls={`faq-answer-${question}`}>
          <img 
            loading="lazy" 
            src={isOpen ? "https://cdn.builder.io/api/v1/image/assets/TEMP/023208285ceb5f70f76b40e43cd09be3a19931282e9a5e748dc753ba650fd2ee?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da" : "https://cdn.builder.io/api/v1/image/assets/TEMP/67db33e663d2bf70461b8df638e6ae3b243dfb2b38abfe25ac9c9a0091273496?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da"} 
            alt={isOpen ? "Close" : "Open"} 
            className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" 
          />
        </button>
      </div>
      {answer && isOpen && (
        <div id={`faq-answer-${question}`} className="mt-4 leading-9 max-md:max-w-full">
          {answer}
        </div>
      )}
    </div>
  );
}

export default FAQItem;