import React from 'react';
import FeatureCard from './FeatureCard';

const features = [
  {
    title: "Disabilitas",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/2423c1ce0acfd3d0bf952ba8f5595e6a91af776eee93530ed8a4c276d44a6285?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da",
    items: [
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/14471255f9992567bf3f3708b9670ed812b18ffc39fc32d2f75280bd2106fdcd?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Akses mudah ke lowongan pekerjaan ramah disabilitas" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ca6b6b4a4c2a1e758a81fd0f018ec0752680b6966ca0bd68086efde90fadbc68?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Kesempatan lebih besar diterima di perusahaan inklusif" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ca6b6b4a4c2a1e758a81fd0f018ec0752680b6966ca0bd68086efde90fadbc68?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Dukungan platform untuk meningkatkan keterampilan kerja" }
    ]
  },
  {
    title: "Perusahaan",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/50d70e3ead5c5485ddd1ec83e5fc100a5584cddf1bbfe17216f49137396f99ed?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da",
    items: [
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/147fd69a4e027a80da13c2047b4b2e3ffd961d2db6beedcbfaeb2af7b8473254?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Mempermudah pencarian tenaga kerja disabilitas" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/147fd69a4e027a80da13c2047b4b2e3ffd961d2db6beedcbfaeb2af7b8473254?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Panduan kebijakan disabilitas terintegrasi" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/14471255f9992567bf3f3708b9670ed812b18ffc39fc32d2f75280bd2106fdcd?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Meningkatkan citra perusahaan sebagai pendukung inklusi" }
    ]
  },
  {
    title: "Disnaker",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/a2fc4184f629806f261cafe7d53348152dccfda19613287dbec61fd226ff2f4f?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da",
    items: [
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/14471255f9992567bf3f3708b9670ed812b18ffc39fc32d2f75280bd2106fdcd?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Memperkuat peran Disnaker dalam inklusi tenaga kerja" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/14471255f9992567bf3f3708b9670ed812b18ffc39fc32d2f75280bd2106fdcd?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Memudahkan pemantauan kebijakan inklusif" },
      { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/14471255f9992567bf3f3708b9670ed812b18ffc39fc32d2f75280bd2106fdcd?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da", text: "Memudahkan evaluasi kebijakan inklusif" }
    ]
  }
];

function FeatureSection() {
  return (
    <section className="flex overflow-hidden flex-col items-center self-center pt-11 pl-1.5 w-full bg-green-400 rounded-xl">
      <h2 className="text-5xl font-bold text-center text-black max-md:max-w-full max-md:text-4xl">
        Memperkuat Karir Inklusif
      </h2>
      <h3 className="text-4xl font-light text-center text-black">
        Fitur / Layanan
      </h3>
      <div className="flex gap-10 justify-center items-start self-stretch pr-px my-12 w-full min-h-[763px] max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-10 justify-center items-start pr-12 pl-14 min-h-[763px] min-w-[240px] w-[1340px] max-md:px-5">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;