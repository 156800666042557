import React from 'react';

function Header() {
  return (
    <header>
      <img 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/dfab24be197a0e04e5b8d92758d56cfdee18e5575fa50ca93770c9d46d40ba76?placeholderIfAbsent=true&apiKey=d537eafe755d47b8a0043f73fe06c6da" 
        alt="Header image" 
        className="object-contain z-10 w-full aspect-[1.53] max-md:max-w-full" 
      />
    </header>
  );
}

export default Header;